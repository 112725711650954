<template>
    <div>
        <transition name="fade" mode="out-in">
            <loader-component v-if="isSubmitting" color="primary" overlay="white" />
        </transition>
        <form @submit.prevent="handleSubmit">
            <details-display-component
                :details="userPersonalDetails"
                title="Persoonlijke gegevens"
            />
            <recess-divider />

            <project-details-section
                :description="formData.description"
                :type="formData.type"
                :name="formData.name"
                :preferred-execution-date="formData.preferredExecutionDate"
                :is-incompany-type="isIncompanyType"
                :number-of-startmoments="formData.numberOfStartmoments"
                :handle-error-message="handleErrorMessage"
                @input:name="formData.name = $event"
                @input:type="formData.type = $event"
                @input:description="formData.description = $event"
                @input:preferred-execution-date="formData.preferredExecutionDate = $event"
                @input:number-of-startmoments="
                    formData.numberOfStartmoments = getGtZeroOrNull($event)
                "
            />
            <recess-divider />

            <provider-details-section
                :provider-name="formData.providerName"
                :account-providers="accountProviders"
                :handle-error-message="handleErrorMessage"
                @input:provider-name="formData.providerName = $event"
                @input:file="formData.offerFile = $event"
            />
            <recess-divider />

            <template v-if="isIncompanyType">
                <participants-details-section
                    :type="formData.type"
                    :min-participants="formData.minParticipants"
                    :max-participants="formData.maxParticipants"
                    :handle-error-message="handleErrorMessage"
                    @input:min-participants="formData.minParticipants = getGtZeroOrNull($event)"
                    @input:max-participants="formData.maxParticipants = getGtZeroOrNull($event)"
                />

                <recess-divider />
            </template>

            <pricing-details-section
                :base-price-without-vat="formData.basePriceWithoutVat"
                :price-per-person-without-vat="formData.pricePerPersonWithoutVat"
                :list-price-without-vat="formData.listPriceWithoutVat"
                :is-incompany-type="isIncompanyType"
                :type="formData.type"
                :total-price="totalPrice"
                :handle-error-message="handleErrorMessage"
                @input:base-price-without-vat="
                    formData.basePriceWithoutVat = getPositiveOrNull($event)
                "
                @input:price-per-person-without-vat="
                    formData.pricePerPersonWithoutVat = getPositiveOrNull($event)
                "
                @input:list-price-without-vat="
                    formData.listPriceWithoutVat = getPositiveOrNull($event)
                "
            />
            <recess-divider />

            <approval-details-section
                :assigned-approver-email="formData.assignedApproverEmail"
                :approver-comment="formData.approverComment"
                :cost-center="formData.costCenter"
                :cost-center-type="formData.costCenterType"
                :handle-error-message="handleErrorMessage"
                :handle-project-approver-query-change="searchProjectApprovers"
                @input:approver-comment="formData.approverComment = $event"
                @input:assigned-approver-email="formData.assignedApproverEmail = $event"
                @input:cost-center="formData.costCenter = $event"
                @input:cost-center-type="formData.costCenterType = $event"
            />
            <recess-divider />

            <div class="d-flex justify-content-between">
                <recess-button
                    :url="{ name: 'my-project-requests' }"
                    title="Terug"
                    variant="tertiary"
                    class="u-min-width-140"
                />
                <recess-button
                    type="submit"
                    title="Opslaan"
                    data-test="submit-btn"
                    variant="primary"
                    class="u-min-width-140"
                />
            </div>

            <recess-alert
                v-if="showFailedPostErrorMessage"
                class="mt-3"
                type="error"
                text="Oeps, er is een onverwachte fout opgetreden. Probeer het later opnieuw"
            />
        </form>
        <project-request-created-confirmation-modal
            :is-visible="showConfirmationModal"
            @close="setShowConfirmationModal(false)"
        />
    </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { required, requiredIf, integer, minValue, maxValue } from 'vuelidate/lib/validators'
import DetailsDisplayComponent from '../../../molecules/DetailsDisplayComponent/DetailsDisplayComponent.vue'
import useCreateProjectRequest from '../../../../composables/useCreateProjectRequest'
import ProjectDetailsSection from './subComponents/ProjectDetailsSection.vue'
import ProviderDetailsSection from './subComponents/ProviderDetailsSection.vue'
import PricingDetailsSection from './subComponents/PricingDetailsSection.vue'
import ApprovalDetailsSection from './subComponents/ApprovalDetailsSection.vue'
import ParticipantsDetailsSection from './subComponents/ParticipantsDetailsSection.vue'
import ProjectRequestCreatedConfirmationModal from './subComponents/ProjectRequestCreatedConfirmationModal.vue'
import LoaderComponent from '../../../atoms/LoaderComponent/LoaderComponent.vue'
import projectCostCenterType from '../../../../constants/projectRequestCostCenterType'
import { isValidMoney, isFuture } from '../../../../modules/validationsHelper'
import projectRequestTypes from '../../../../constants/projectRequestTypes'

export default {
    components: {
        ProjectDetailsSection,
        ProviderDetailsSection,
        PricingDetailsSection,
        ApprovalDetailsSection,
        ProjectRequestCreatedConfirmationModal,
        LoaderComponent,
        DetailsDisplayComponent,
        ParticipantsDetailsSection
    },
    setup() {
        const {
            handleSubmit,
            userPersonalDetails,
            formData,
            handleErrorMessage,
            searchProjectApprovers,
            showConfirmationModal,
            setShowConfirmationModal,
            showFailedPostErrorMessage,
            searchProviders,
            accountProviders,
            fetchAccountProviders,
            isSubmitting,
            isIncompanyType
        } = useCreateProjectRequest()

        onMounted(async () => {
            await fetchAccountProviders()
        })

        const getGtZeroOrNull = (value) => (value > 0 ? +value : null)
        const getPositiveOrNull = (value) => (value >= 0 ? +value : null)

        const totalPrice = computed(() => {
            if (!isIncompanyType) return null

            const fixedPrice = formData.basePriceWithoutVat ?? 0
            const pricePerPerson = formData.pricePerPersonWithoutVat ?? 0
            const maxParticipants = formData.maxParticipants ?? 0
            const numberOfStartmoments = formData.numberOfStartmoments ?? 1

            const totalPriceCalculation = fixedPrice + pricePerPerson * maxParticipants

            if (formData.type === projectRequestTypes.IncompanyWithStartmoments)
                return totalPriceCalculation * numberOfStartmoments

            return totalPriceCalculation
        })

        return {
            handleSubmit,
            userPersonalDetails,
            formData,
            handleErrorMessage,
            searchProjectApprovers,
            showConfirmationModal,
            setShowConfirmationModal,
            showFailedPostErrorMessage,
            searchProviders,
            accountProviders,
            isSubmitting,
            isIncompanyType,
            getGtZeroOrNull,
            getPositiveOrNull,
            totalPrice
        }
    },
    validations() {
        return {
            formData: {
                name: { required },
                type: { required },
                basePriceWithoutVat: { required, isValidMoney },
                costCenterType: { required },
                costCenter: {
                    required: requiredIf(
                        () => this.formData.costCenterType === projectCostCenterType.Central
                    )
                },
                providerName: { required },
                assignedApproverEmail: { required },
                preferredExecutionDate: {
                    required: requiredIf(() => this.isIncompanyType),
                    isFuture
                },
                pricePerPersonWithoutVat: {
                    required: requiredIf(() => this.isIncompanyType),
                    isValidMoney
                },
                listPriceWithoutVat: {
                    required: requiredIf(() => this.isIncompanyType),
                    isValidMoney
                },
                minParticipants: {
                    required: requiredIf(() => this.isIncompanyType),
                    integer,
                    minValue: minValue(0),
                    maxValue: maxValue(99999)
                },
                maxParticipants: {
                    required: requiredIf(() => this.isIncompanyType),
                    integer,
                    minValue: minValue(this.formData.minParticipants),
                    maxValue: maxValue(99999)
                },
                numberOfStartmoments: {
                    required: requiredIf(
                        () => this.formData.type === projectRequestTypes.IncompanyWithStartmoments
                    ),
                    integer,
                    minValue: minValue(0),
                    maxValue: maxValue(99999)
                }
            }
        }
    }
}
</script>
