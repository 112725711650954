import initApp from './init'

initApp({
    oplzApiUrl: process.env.VUE_APP_OPLZ_API_URL,
    oplzSocialLearningApiUrl: process.env.VUE_APP_OPLZ_SOCIAL_LEARNING_API_URL,
    oplzGeoLocationApiUrl: process.env.VUE_APP_OPLZ_GEO_LOCATION_API_URL,
    oplzAiApiUrl: process.env.VUE_APP_OPLZ_AI_API_URL,
    oplzAdminUrl: process.env.VUE_APP_OPLZ_ADMIN_URL,
    environment: process.env.VUE_APP_CURRENT_ENVIRONMENT,
    appInsightsKey: process.env.VUE_APP_APPINSIGHTS_INSTRUMENTATION_KEY,
    oplzVueAppSubscriptionKey: process.env.VUE_APP_SUBSCRIPTIONKEY,
    oidcConfig: [
        {
            key: "authority",
            value: process.env.VUE_APP_OIDC_AUTHORITY
        },
        {
            key: "client_id",
            value: process.env.VUE_APP_OIDC_CLIENT_ID
        },
        {
            key: "post_logout_redirect_uri",
            value: "/signout-oidc-oplz"
        },
        {
            key: "redirect_uri",
            value: "/signin-oidc-oplz"
        },
        {
            key: "response_type",
            value: "code"
        },
        {
            key: "scope",
            value: "openid profile apigateway oplz rw:post rw:reply rw:vote r:city r:c-recommend r:synonyms"
        },
        {
            key: "silent_redirect_uri",
            value: "/silent-renew"
        }
    ]
})
