<template>
  <section>
    <h4>
      Financieel
      <recess-tooltip v-if="isIncompanyType" class="d-inline-block pl-2">
        <template #content>
          <div class="u-max-width-500">
            Geef de prijzen op zoals doorgegeven door de opleider/uitvoerder.
            <template v-if="isIncompanyWithStartMoments">
              <br />
              Het aantal deelnemers X het aantal startmomenten bepaald het
              maximumbedrag voor de purchase order reservering.
            </template>
          </div>
        </template>
      </recess-tooltip>
    </h4>
    <div class="row pb-3">
      <div class="col-md-6 w-100">
        <recess-input
          :value="basePriceWithoutVat"
          :error-message="handleErrorMessage('basePriceWithoutVat')"
          :placeholder-text="moneyPlaceholder"
          type="number"
          min="0.00"
          step="0.01"
          data-test="basePriceWithoutVat-input"
          class="w-100 mr-md-3 mb-3 mb-md-0"
          label-text="Vaste prijs (excl. BTW) *"
          @input="(val) => $emit('input:base-price-without-vat', val)"
        />
      </div>

      <div v-if="isIncompanyType" class="col-md-6 w-100">
        <recess-input
          :value="pricePerPersonWithoutVat"
          :placeholder-text="moneyPlaceholder"
          :error-message="handleErrorMessage('pricePerPersonWithoutVat')"
          type="number"
          min="0.00"
          step="0.01"
          data-test="pricePerPersonWithoutVat-input"
          class="w-100 mr-md-3 mb-3 mb-md-0"
          label-text="Prijs per deelnemer (excl. BTW) *"
          @input="(val) => $emit('input:price-per-person-without-vat', val)"
        />
      </div>
    </div>

    <div v-if="isIncompanyType" class="row pb-3">
      <div class="col-md-6 w-100">
        <recess-input
          type="number"
          :value="listPriceWithoutVat"
          :placeholder-text="moneyPlaceholder"
          :error-message="handleErrorMessage('listPriceWithoutVat')"
          min="0.00"
          step="0.01"
          data-test="listPriceWithoutVat-input"
          class="w-100 mr-md-3 mb-3 mb-md-0"
          label-text="Weergave prijs (per deelnemer, excl. BTW) *"
          @input="(val) => $emit('input:list-price-without-vat', val)"
        />
      </div>

      <div class="col-md-6 w-100">
        <recess-input
          :value="formatPriceWithZeros(totalPrice)"
          disabled
          data-test="totalPrice-input"
          class="w-100 mr-md-3 mb-3 mb-md-0"
          label-text="Totaal o.b.v. max. deelnemers (excl. BTW)"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { computed } from 'vue'
import priceFormatter from '../../../../../modules/priceFormatter'
import projectRequestTypes from '../../../../../constants/projectRequestTypes'

export default {
    props: {
        basePriceWithoutVat: {
            type: Number,
            default: null
        },
        pricePerPersonWithoutVat: {
            type: Number,
            default: null
        },
        listPriceWithoutVat: {
            type: Number,
            default: null
        },
        totalPrice: {
            type: Number,
            default: null
        },
        isIncompanyType: {
            type: Boolean,
            default: false
        },
        type: {
            type: Number,
            default: null
        },
        handleErrorMessage: {
            type: Function,
            default: () => null
        }
    },
    emits: [
        'input:base-price-without-vat',
        'input:price-per-person-without-vat',
        'input:list-price-without-vat'
    ],
    setup(props) {
        const isIncompanyWithStartMoments = computed(
            () => props.type === projectRequestTypes.IncompanyWithStartmoments
        )

        return {
            isIncompanyWithStartMoments,
            moneyPlaceholder: '€ 0,00',
            formatPriceWithZeros: priceFormatter.formatPriceWithZeros
        }
    }
}
</script>
