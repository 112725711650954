import axios from 'axios'
import { getUserManager } from '../modules/oidc-client'

let oplzClientCore = null
let socialLearningClientCore = null
let geoLocationClientCore = null
let aiClientCore = null
let oplzVue2AppSubscriptionKey
const apimSubscriptionKeyHeader = 'Ocp-Apim-Subscription-Key'

export default {
    initializeClient({ oplzApiUrl, oplzSocialLearningApiUrl, oplzGeoLocationApiUrl, oplzAiApiUrl, oplzVueAppSubscriptionKey }) {
        oplzClientCore = axios.create({
            baseURL: oplzApiUrl
        })

        socialLearningClientCore = axios.create({
            baseURL: oplzSocialLearningApiUrl
        })

        geoLocationClientCore = axios.create({
            baseURL: oplzGeoLocationApiUrl
        })

        aiClientCore = axios.create({
            baseURL: oplzAiApiUrl
        })

        oplzVue2AppSubscriptionKey = oplzVueAppSubscriptionKey
    }
}

const getClientToken = async () => {
    try {
        const userManager = await getUserManager()
        const user = await userManager.getUser()

        if(!user || !user?.access_token) return null

        return `Bearer ${user?.access_token}`
    } catch (error) {
        throw new Error(error)
    }
}

const setAuthorizationHeader = async client => {
    const token = await getClientToken()
  
    if (token) {
        client.defaults.headers.common.Authorization = token
    }
}

const setSubscriptionKeyOnHeader = async client => {
    client.defaults.headers.common[apimSubscriptionKeyHeader] = oplzVue2AppSubscriptionKey
}


export const getOplzClient = async () => {
    try {
        if (!oplzClientCore?.defaults?.headers?.common?.Authorization) {
            await setAuthorizationHeader(oplzClientCore)
        }

        return oplzClientCore
    } catch (error) {
        throw new Error("Failed to get oplz client", { cause: error })
    }
}

export const getSocialLearningClient = async () => {
    try {
        if (!socialLearningClientCore?.defaults?.headers?.common?.Authorization) {
            await setAuthorizationHeader(socialLearningClientCore)
        }

        if (!socialLearningClientCore?.defaults?.headers?.common[apimSubscriptionKeyHeader]) {
            await setSubscriptionKeyOnHeader(socialLearningClientCore)
        }
        
        return socialLearningClientCore
    } catch (error) {
        throw new Error("Failed to get social learning client", { cause: error })
    }
}

export const getGeoLocationClient = async () => {
    try {
        if(!geoLocationClientCore?.defaults?.headers?.common?.Authorization) {
            await setAuthorizationHeader(geoLocationClientCore)
        }
        
        if (!geoLocationClientCore?.defaults?.headers?.common[apimSubscriptionKeyHeader]) {
            await setSubscriptionKeyOnHeader(geoLocationClientCore)
        }

        return geoLocationClientCore
    } catch (error) {
        throw new Error("Failed to get geo location client", { cause: error })
    }
}

export const getAiClient = async () => {
    try {
        if(!aiClientCore?.defaults?.headers?.common?.Authorization) {
            await setAuthorizationHeader(aiClientCore)
        }
        
        if (!aiClientCore?.defaults?.headers?.common[apimSubscriptionKeyHeader]) {
            await setSubscriptionKeyOnHeader(aiClientCore)
        }

        return aiClientCore
    } catch (error) {
        throw new Error("Failed to get ai client", { cause: error })
    }
}