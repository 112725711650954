const oplzConfigurationModule = {
    namespaced: true,

    state() {
        return {
            oplzApiUrl: '',
            oplzAdminUrl: '',
            oplzSocialLearningApiUrl: '',
            oplzGeoLocationApiUrl: '',
            oplzAiApiUrl: '',
            oplzVueAppSubscriptionKey: ''
        }
    },

    getters: {
        oplzApiUrl: state => {
            return state.oplzApiUrl
        },

        oplzSocialLearningApiUrl: state => {
            return state.oplzSocialLearningApiUrl
        },

        oplzGeoLocationApiUrl: state => {
            return state.oplzGeoLocationApiUrl
        },

        oplzAiApiUrl: state => {
            return state.oplzAiApiUrl
        },

        oplzAdminUrl: state => {
            return state.oplzAdminUrl
        },
        
        oplzVueAppSubscriptionKey: state => {
            return state.oplzVueAppSubscriptionKey
        }
    },

    mutations: {
        setOplzApiUrl(state, payload) {
            state.oplzApiUrl = payload
        },

        setOplzAdminUrl(state, payload) {
            state.oplzAdminUrl = payload
        },

        setOplzSocialLearningApiUrl(state, payload) {
            state.oplzSocialLearningApiUrl = payload
        },

        setOplzAiApiUrl(state, payload) {
            state.oplzAiApiUrl = payload
        },

        setOplzGeoLocationApiUrl(state, payload) {
            state.oplzGeoLocationApiUrl = payload
        },
        
        setOplzVueAppSubscriptionKey(state, payload) {
            state.oplzVueAppSubscriptionKey = payload
        }
    }
}

export default oplzConfigurationModule
